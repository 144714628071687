import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 列表
  getPlanPaged: "/api/WarehouseService/ChannelReturnPlan/GetPlanPaged",
  // 查看轨迹
  getTrack: "/api/WarehouseService/ChannelReturnPlanTrack/GetTrack",
  // 查询可选服务费用配置
  getServiceFeeConfig: "/api/WarehouseService/ChannelReturnPlan/GetServiceFeeConfig",
  // 获取退货计划费用信息
  getChannelReturnPlanFee: "/api/WarehouseService/ChannelReturnPlan/GetFee",
  // 创建退货计划
  createChannelReturnPlan: "/api/WarehouseService/ChannelReturnPlan/Create",
  // 计划基本信息计划详细
  getDetailById: "/api/WarehouseService/ChannelReturnPlan/GetDetailById",
  // 操作记录
  getOperateReceive: "/api/WarehouseService/ChannelReturnPlan/GetOperateReceive",
  // 修改包裹单号
  editPackageNo: "/api/WarehouseService/ChannelReturnPlan/EditPackageNo",
  // 整单退款
  refundChannelReturnPlan: "/api/WarehouseService/ChannelReturnPlan/Refund",
  //检查仓库时候存在包裹信息
  checkPackage:"/api/WarehouseService/ChannelReturnPlan/CheckTemporaryStoragePackage",
  //检查包裹是否销毁了
  isDestroyPackage:"/api/WarehouseService/ChannelReturnPlan/TemporaryStorageDestroyPackage",
  //获得暂存配置
  getRtsConfig:"/api/WarehouseService/ReturnTemporaryStorage/GetRtsConfig",
  // 导出
  exportChannelReturnPlan: "/api/WarehouseService/ChannelReturnPlan/Export",
};

export const getPlanPaged = (data) => {
  return client.request({
    url: Api.getPlanPaged,
    data,
    method: "POST",
  });
};

export const getTrack = (data) => {
  return client.request({
    url: Api.getTrack,
    data,
    method: "POST",
  });
};

export const getServiceFeeConfig = (data) => {
  return client.request({
    url: Api.getServiceFeeConfig,
    data,
    method: "POST",
  });
};

export const getChannelReturnPlanFee = (data) => {
  return client.request({
    url: Api.getChannelReturnPlanFee,
    data,
    method: "POST",
  });
};

export const createChannelReturnPlan = (data) => {
  return client.request({
    url: Api.createChannelReturnPlan,
    data,
    method: "POST",
  });
};

export const getDetailById = (data) => {
  return client.request({
    url: Api.getDetailById,
    data,
    method: "POST",
  });
};

export const getOperateReceive = (data) => {
  return client.request({
    url: Api.getOperateReceive,
    data,
    method: "POST",
  });
};

export const editPackageNo = (data) => {
  return client.request({
    url: Api.editPackageNo,
    data,
    method: "POST",
  });
};

export const refundChannelReturnPlan = (data) => {
  return client.request({
    url: Api.refundChannelReturnPlan,
    data,
    method: "POST",
  });
};


export const checkPackage = (packageNo,receiveWarehouseId) => {
  return client.request({
    url: Api.checkPackage,
    data:{receiveWarehouseId,packageNo},
    method: "POST",
  });
};

export const isDestroyPackage = ({packageNo,packageNo1,packageNo2,receiveWarehouseId}) => {
  return client.request({
    url: Api.isDestroyPackage,
    data:{receiveWarehouseId,packageNo,packageNo1,packageNo2},
    method: "POST",
  });
};

export const getRtsConfig = (data) => {
  return client.request({
    url: Api.getRtsConfig,
    data,
    method: "POST",
  });
};

export const exportChannelReturnPlan = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportChannelReturnPlan);
};